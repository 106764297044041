// i18n.js
import Module from '@/views/Module.vue';
import MyAccount from '@/views/MyAccount.vue';
import Rewards from '@/views/Rewards.vue';
import WhatsOn from '@/views/WhatsOn.vue';
import Video from '@/views/video.vue';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
// 第一个大写，后面小写，除了eXtra   Bosch，ID，
// 例外情况，最大的标题
Vue.use(VueI18n);

const messages = {
  en: {
    // 英语
    message: {
      // 公共头部和底部
      PleaseCheckTheSignUpForEXtraProgram:'Please check the sign up for eXtra program!',
      monthlyOrderImport: 'Monthly order import',
      SKUOrderImport: 'SKU order import',
      FileCode: 'File code',
      FileName: 'File name',
      OrderMonth: 'Order month',
      importTime: 'Import time',
      Operate: 'Operate',
      importNow: 'Import now',
      DataFile: 'Data file',
      ClickToUpload: 'Click to upload',
      TemplateFile: 'Template file',

      clickToDownloadTheMonthlyOrderTemplateFile: 'click to download the monthly order template file',
      clickToDownloadTheSKUOrderTemplateFile: 'Click to download the SKU order template file',
      importData: 'Import data',


      manualUploading: 'Manual uploading',
      Home: "Home",
      WhatSon: "What's on",
      Rewards: "Rewards",
      Module: "Module",
      TechWiki: "Auto tech",
      MyAccount: "My account",
      BOSCHAZPortal: "Bosch Business Portal",
      YourApplicationIsPendingReview: "Your application is pending review",
      CorporateInformation: "Corporate information",
      LegalNotice: "Legal notice",
      DataProtectionPolicy: "Data protection policy",
      PrivacySettings: "Privacy settings",
      RobertBoschGmbH: "© 2021 - 2025 Robert Bosch GmbH, all rights reserved",

      // 选择国家页
      SelectCountry: "Select country",
      WarmingReminderYouCanOnlyAccessMalyssiaNow: "You can't access this country now. It will be coming sonn! Please stay informed with us！",

      // 添加员工
      AddEmployee: "Add Employee",
      FillInEmployeeInfo: "Fill in employee info",
      SheHeIs: "She/He is",
      YourApplicationIsAentToYourTheOwnerOfYourCompanyForApproval: 'Your application is sent to the owner of your company for approval .',


      // 表单
      Registration: "Registration",
      Step1: "Step 1",
      Step2: "Step 2",
      MobileNo: "Mobile NO.",
      FirstName: "First name",
      LastName: "Last name",
      CompanyName: "Company name",
      Email: "E-mail",
      EmailOptional: "Email (optional）",
      FullAddress: "Full address",
      Address: "Address",
      City: "City",
      PleaseEnter: "Please enter",
      ProvinceState: "Province / State",
      ZipCode: "Zip code",
      Continue: "Continue",
      Step3: "Step 3",
      IAmA: "I am a",
      IAm: "I am",
      RepairSegment: "Repair segment",
      VehicleSegment: "Vehicle segment",
      PreferredDistributor: "Preferred distributor",
      Workshop: "Workshop",
      Retailer: "Retailer",
      TradeLevel: "Trade level",
      PortalId: "Portal ID",
      Distributor: "Distributor",
      Diesel: "Diesel",
      Gasoline: "Gasoline",
      OthersEV: "Others (EV)",
      Motorcycle: "Motorcycle",
      PassengerCars: "Passenger cars",
      TrucksBus: "Trucks/Bus",
      NoOfWorkbay: "No. of workbay",
      FillIn: "Fill in",
      Namecard: "Namecard",
      TakeNamecard: "Take namecard",
      CompanyShopfront: "Company shopfront",
      PleaseUploadYourCompanyNamecard: "Please upload your company namecard",
      TakePhotoOfCompanyShopfront: "Take photo of company shopfront",
      PleaseUploadYourCompanyShopfront: "Please upload your company shopfront",
      SignUpForEXtraProgram: "Sign up for eXtra program",
      PleaseCheckAgain: "Please check again",
      IsTheGivenInformationCorrect: "Is the given information correct",
      DoYouAgreeOurPrivacyPolicy: "Do you agree our privacy policy",
      DoYouAgreeOurTermsConditions: "Do you agree our terms&Conditions",
      ConfirmToRegister: "Confirm to register",
      PleaseCheckIfTBD: "Please check if the given information is correct?（TBD)",
      Back: "Back",
      Confirm: "Confirm",
      PleaseCheckIfTheGivenInformationIsCorrect: "Please check if the given information is correct?",
      PleaseEnterTheRequiredInfo: "Please enter the required info",
      pleaseMakeSureIfYouWannaSignUpForEXtraProgram: "Please make sure if you wanna sign up for eXtra program",
      AlreadySubmittedPendingApproval: "Already submitted , pending approval",
      OwnerMobileNo: "Business/Office number.",
      Manager: "Manager",
      Employee: "Employee",
      Owner: "Responsible person",
      RequestANewOTP: "Request a new otp",
      YouHaveSelectdSMSAsNotificationChannel: "You have selectd SMS as notification channel, you will shortly receive an OTP to the mobile number you provided. please enter the OTP to complete your registration",
      DataProtectionGuidelines: "Data protection guidelines",
      GeneralTermsConditions: "General terms and conditions",
      PleaseEnterYourPhoneNumber: "Please enter your phone number",
      CellPhoneNumberIsRequired: "Cell phone number is required",
      pleaseAgreeOurTermsAndConditions: "Please agree our terms and conditions.",
      pleaseAgreeOurDataProtectionPolicy: "Please agree our Data protection policy.",
      pleaseAgreeOurLegalNotice: "Please agree our legal notice.",
      PleaseChooseYourRegion: "Please choose your region",
      PleaseEnterOTP: "Please enter otp",
      LoginToProceed: "Login to proceed",
      Login: "Login",
      RegisterNow: "Register now",
      DontHaveAnAccount: "Don’t have an account",
      OTP: "OTP",
      RequestANewOTP: "Request a new otp",
      YourApplicationIsPendingApproval: "Your application is pending approval, please wait a second!",
      success: "Success！",
      rule_list: "History version",
      AccountData: "Account Data",
      CountryRegion: "Country / Region",
      Role: "Role",
      ModifyBasicInformation: "Company photo",
      PleaseFillInYourEmail: "Your Email format is not correct, please enter again!",
      CompanyData: "Company data",
      eXtraID: "eXtra ID",
      ModifyInformation: "Modify information",
      ModifyKeyInformation: "Modify key information",
      ChangeCompanyPhoto: "Change company photo",
      LogOut: "Log out",
      NoDataAvailable: "No data available",
      Search: "Search",
      Modify: "Modify",
      PleaseCheckTheDataProtectionPolicyAndLegalNotice: "Please check data protection policy and legal notice!",

      // 人员管理
      RoleManagement: "Role management",
      Time: "Time",
      Refuse: "Refuse",
      Accept: "Accept",
      DoYouConfirmToApproveIt: "Do you confirm to approve it?",
      DoYouConfirmToRejectIt: "Do you confirm to reject it?",
      PleaseConfirmAgain: "Notification",
      RoleInformation: "Role information",
      Name: "Name",
      Activate: "Activate",
      Disable: "Disable",
      IdentitySettings: "Identity settings",
      DoYouConfirmToChangeThisInformation: "Do you confirm to change this information?",
      DoYouConfirmToActivateThisAccount: "Do you confirm to activate this account？",
      DoYouConfirmToDisableThisAccount: "Do you confirm to disable this account?",

      // article
      PurchaseRecord: "Purchase record",
      TotalPurchase: "Total purchase",
      valueSGD: "value (SGD)",
      TotalPurchaseValue: "Total purchase value",
      TotalPurchaseAmt: "Total purchase amt",
      ProcurementRecords: "Procurement records",
      View: "View",
      LatestArticles: "Latest articles",
      LearnMore: "Learn more >",

      // Autoparts
      AutoParts: "Auto parts",
      ViewMore: "View more >",
      BoschGroup: "More About Bosch Group",
      BoschMobilityAftermarket: "Bosch Mobility Aftermarket Homepage",

      // BSSList
      WelcomeBoschModulePartner: "Welcome Bosch Module partner!",
      MyBenefits: "My benefits >",
      MoreInfoAboutModule: "More info. about module >",
      AnnualPartsTarget: "Annual parts target",
      StillToGo: "Still-to-go",
      ContractTarget: "Contract target",
      TotalAchievement: "Total achievement",
      Year: "Year",
      ActualSales: "Actual sales",
      ContractPeriod: "Contract period",
      years: "Years",
      JoinDate: "Contract start date",
      KeepUpTheGreatProgress: 'Keep up the great progress!',
      DealerWillGetInTouchWithYouSoon: "Dealer will get in touch with you soon.",
      ViewMyContract: "View my contract",
      Marketing: "Marketing",
      promotionZone: "Promotion zone",
      Attended: "Attended",
      Achieved: 'Achieved',
      Received: "Received",

      Done: "Done",
      Abandoned: "Abandoned",
      to: "To",
      CurrentPurchase: "Current purchase",
      SGD: "SGD",
      MarketingPromotionZone: "Marketing promotion zone",
      NotAttended: "Not Available",

      // contact_us
      Like: "Like",
      Comment: "Comment",
      SaySometihing: "Say sometihing",
      Sending: "Send",
      User: "User",
      PleaseTypeWords: "Please type words",
      AutomotiveTech: "Automotive Tech",
      views: "views",

      // Home
      FunctionalityInDevelopment: "Functionality in development",

      // Module
      Apply: "Apply",
      JoinUsLogIn: "Join us / Log in",
      JoinUs: "Join us",
      BoschModuleConcept: "Bosch module concept",
      IHaveReadAndAcceptedTheBoschModulePolicy: "I have read and accepted the Bosch module policy.",
      SignNow: "Sign Now",
      PleaseFillInTheBelowArea: "Please fill in the below area",
      PleaseFillInYourFirstNameUnrecognizedOrIncorrectSignatureWillLeadToRegistrationFailure: "Please fill in your First name. Unrecognized or incorrect signature will lead to registration failure",
      PlaeaseFillInYourFirstName: "Plaease fill in your first name",
      pleaseCheckTheBoschModulePolicy: "please check the Bosch module policy",
      KindlyProvideSignatureOrFullName: "Kindly provide full name",

      // MyAccount
      loggedInSuccessfully: "Logged in successfully!",
      IAlreadyHaveAnOTP: "I already have an otp",
      Tel: "Tel",
      PendingIdentityForReview: "Pending identity for review",
      EnterBoschModuleTiering: "Enter Bosch Module tiering >",
      CurrentMember: "Current member",
      EarnPointsEveryDay: "Earn points every day",
      eXtraMall: "eXtra mall",
      eXtraPoints: "eXtra points",
      eXtraPoint: "eXtraPoint",
      Go: "Go",
      Redeem: "Redeem",
      MyServices: "My services",
      eXtra: "eXtra",
      Order: "Order",
      Points: "Points",
      Details: "Details",
      Personal: "Personal",
      Data: "Data",
      Privacy: "Privacy",
      Clause: "Clause",
      PersonalData: "Personal data",
      eXtraWokrshopCandidate: "Membership pending",
      KevinCarRepairServiceCenter: "Kevin car repair service center",
      MoreInfoAboutCompany: "More info about company",
      MoreInfoAboutYou: "More info about you",
      MyFirstName: "My first name",
      MyLastName: "My last name",
      BossMobileNumber: "Business/Office number",

      // Recordltem
      time: "Time",
      item: "Item",
      Qty: "Qty",
      PN: "PN",
      dealer: "Dealer",
      Download: "Download",

      // Rewards
      expiresOn: "Expires on",
      ActivityRules: "Activity rules",
      CheckInDone: "Check-in done",
      CheckInNOW: "Check-in now",
      ThePointsDataWillUpdateMonthlyPleaseStayInformedWithUs: '*The points data will update monthly.Please stay informed with us.',

      // Video
      FoundTheQRCode: "Found the qr code",
      PleaseUseYourMobilePhoneToLog: "Please use your mobile phone to log on so as to scan product successfully!",
      PleaseAllowAuthorizedUseOfYourPhone: "Please allow authorized use of your phone camera and scan again!",

      // WhatsOn
      ThoEvontTeasorGroupOllors: "Tho evont teasor group ollors tho possibility to prominently displny several evonts within one compent.",

      //刘新增 10/10
      ModuleJoinData: 'Module join date',
      ViewMyContract: 'View my contract',
      NoContractHint: "We haven't found your contract, please contact your Bosch sales.",
      NoticeOfExpirationOfContract: "Your contract has expired! Please await Bosch arrangement for renewal.",

      ChannelPromotion: 'Channel promotion',
      OK: 'OK',
      Congratulations: "Congratulations!",
      YouHaveAchievedTheTarget: "You have achieved the target. ",
      PleaseAwaitBoschArrangementForYourEntitlement: 'Please await Bosch arrangement for your entitlement!',
      ReceivedTheRewards: 'Received the rewards',
      NotYetReceived: "Not yet received",

      // (10/12)
      WorkRole: 'Work role',
      ICanNotFindMyDistributor: 'Distributor name not found',
      AllAutoparts: 'All autoparts',
      BatterySpecialist: 'Battery specialist',
      Others: 'Others',
      Cancel: 'Cancel',
      PleaseEnterTheCorrectCompanyNameAndMobileNumber: 'Please enter the correct company name and mobile number',
      ALL: 'All',
      AccessOurFullRangeOfAvailableProductsOnTheGoSimplyClickOnYourProductGroupOfInterestToDiveIn: 'Access our full range of available products on the go - simply click on your product group of interest to dive in!',
      searchPartnumberItem: 'Search partnumber、item',
      searchPartnumberDealer: 'Search partnumber、dealer',
      Send: "Send",
      Submit: "Submit",
      BoschAutopartsCustomerPortal: "Bosch Business Portal",

      //(10/14)
      Reset: "Reset",
      SelectAlanguage: 'Select a language',
      NotFilled: 'Not filled',
      Yes: 'Yes',
      NO: 'NO',
      StartTime: 'Start time',
      EndTime: 'End time',
      Product: 'Product',
      PendingApproval: 'Pending approval',
      AuditRejection: 'Audit rejection',
      registered: 'Registered',
      ResignedDisabled: ' Resigned/Disabled',
      frozen: 'frozen',
      PleaseRnterYourLoginAccount: 'Please enter your loginAccount',
      AddEmployee: 'Add employee',
      ViewDetails: 'View details',
      YourRegistrationApplicationIsCurrentlyUnderReview: 'Your registration application is currently under review.',
      IHaveTakenNoteOfThe: 'I have taken note of the',
      DataProtectionNotice: 'Data protection policy',
      ContactUs: 'Contact us',
      YourDetails: 'Your details',
      InquiryDetails: 'Inquiry details',
      Description: 'Description',
      Subject: 'Subject',
      ModulesSignUp: 'Modules sign up',
      PleaseFillInAllTheRequiredInformation: 'Please fill in all the required information!',
      Expired: 'Expired',
      PleaseAccessThroughMobileBrowser: 'Please access through mobile browser',
      Enter: "Enter",
      Partnership: 'Partnership',
      EGGMALL: 'EGG MALL',
      CreateTheEGGMallAccount:'Create the EGG Mall account',
      Egg_hint:'You will authorize us to use your information to create the Egg Mall account, which will be bound to our account.',
      IAccept:'I accept',
      Egg_check_Policy:'《EGG Mall Privacy Policy》',
      ConfirmToCreate:'Confirm to create',
      PleaseCheckAndAcceptThePrivacyPolicy:'Please check and accept the privacy policy',
      CreationComplete:'Creation complete！',
      OverallAnnualBoshPurchase:'Overall annual Bosch purchase',

    }
    // 未接：
    // 签到页扫码得奖英文拼接
    // that.$t("message.PleaseAllowAuthorizedUseOfYourPhone") BSSList
    // {{ $t("message.ThoEvontTeasorGroupOllors") }}
  },
  id: {
    // 印尼
    message: {
      PleaseCheckTheSignUpForEXtraProgram:'Silakan periksa dan setuju untuk mendaftar di platform eXtra!',
      monthlyOrderImport: 'Import perintah bulanan',
      SKUOrderImport: 'Import perintah SKU',
      FileCode: 'Kode berkas',
      FileName: 'Nama berkas',
      OrderMonth: 'Bulan perintah',
      importTime: 'Waktu mengimport',
      Operate: 'Operasi',
      importNow: 'Import sekarang',
      DataFile: 'Berkas data',
      ClickToUpload: 'Klik untuk mengunggah',
      TemplateFile: 'Berkas templat',
      clickToDownloadTheMonthlyOrderTemplateFile: 'klik untuk mengunduh berkas templat perintah bulanan',
      clickToDownloadTheSKUOrderTemplateFile: 'Klik untuk mengunduh berkas templat perintah SKU',
      importData: 'Import data',


      manualUploading: 'Mengupload secara manual',
      PleaseFillInAllTheRequiredInformation: 'Silakan isi semua informasi yang diperlukan!',
      Submit: 'Kirim',
      ModulesSignUp: 'Registrasi modul',
      Subject: 'tema',
      Description: 'deskripsikan',
      InquiryDetails: 'Cari rincian',
      YourDetails: 'Informasi rincianmu',
      ContactUs: 'hubungi kami',
      DataProtectionNotice: 'Kebijakan perlindungan data',
      IHaveTakenNoteOfThe: 'Aku menyadari',
      YourRegistrationApplicationIsCurrentlyUnderReview: 'Aplikasi pendaftaran Anda saat ini sedang diuji.',
      ViewDetails: 'Tampilkan informasi rincian',
      AddEmployee: 'Tambahkan karyawan',
      PleaseRnterYourLoginAccount: 'Aplikasi telah dikirim di toko perbaikan. Silakan beritahu bos untuk pemeriksaan',
      PendingApproval: 'Revisi tunggu',
      AuditRejection: 'Review gagal',
      registered: 'aktif',
      ResignedDisabled: 'Lumpuhkan/Batalkan',
      frozen: 'beku',
      Product: 'produk',
      StartTime: 'waktu awal',
      EndTime: 'Waktu akhir',
      Yes: 'ya',
      NO: 'Tidak',
      NotFilled: 'Tidak ada data tersedia',
      OK: 'bagus',
      ReceivedTheRewards: 'Hadiah yang diterima',
      NotYetReceived: 'Belum diterima',
      YouHaveAchievedTheTarget: 'Anda telah mencapai tujuan Anda.',
      PleaseAwaitBoschArrangementForYourEntitlement: 'Tolong tunggu Bosch untuk mengatur hakmu!',
      Congratulations: 'Selamat',
      ChannelPromotion: 'Promosi saluran',
      SelectAlanguage: 'tukar bahasa',
      Home: 'Laman Utama',
      WhatSon: 'Yang tersedia',
      Rewards: 'Hadiah',
      Module: 'Modul',
      TechWiki: 'Teknologi Otomotif',
      MyAccount: 'Akun Saya',
      BOSCHAZPortal: 'Portal Pelanggan Bosch Autoparts',
      YourApplicationIsPendingReview: 'Aplikasi Anda sedang menunggu peninjauan',
      CorporateInformation: 'Informasi perusahaan',
      LegalNotice: 'Pemberitahuan hukum',
      DataProtectionPolicy: 'Kebijakan perlindungan data',
      PrivacySettings: 'Pengaturan privasi',
      RobertBoschGmbH: '© 2021 - 2025 Robert Bosch GmbH, semua hak dilindungi undang-undang',

      // 选择国家页:'',
      SelectCountry: 'Pilih Negara',
      WarmingReminderYouCanOnlyAccessMalyssiaNow: 'Saat ini, hanya platform Malaysia kami yang tersedia untuk diakses. Kami bekerja keras untuk segera menghadirkan negara lain secara online—nantikan pembaruan!',

      // 添加员工:'',
      AddEmployee: 'Tambahkan karyawan',
      FillInEmployeeInfo: 'Isi info karyawan',
      SheHeIs: 'Dia adalah',
      YourApplicationIsAentToYourTheOwnerOfYourCompanyForApproval: 'Aplikasi Anda dikirim ke pemilik perusahaan Anda untuk disetujui.',


      // 表单:'',
      Registration: 'Pendaftaran',
      Step1: 'Langkah 1',
      Step2: 'Langkah 2',
      MobileNo: 'Nomor ponsel.',
      FirstName: 'Nama depan',
      LastName: 'Nama belakang',
      CompanyName: 'Nama Perusahaan',
      Email: 'E-mail',
      EmailOptional: 'E-mail (opsional)',
      FullAddress: 'Alamat lengkap',
      Address: 'Alamat lengkap',
      City: 'Kota',
      PleaseEnter: 'Silahkan masukkan',
      ProvinceState: 'Provinsi/negara bagian',
      ZipCode: 'Kode pos',
      Continue: 'terus',
      Step3: 'Langkah 3',
      IAmA: 'Saya adalah  ......',
      IAm: 'Saya adalah  ......',
      RepairSegment: 'Segmen Perbaikan',
      VehicleSegment: 'Segmen Kendaraan',
      PreferredDistributor: 'Distributor pilihan',
      Workshop: 'Bengkel ',
      Retailer: 'Pengecer',
      TradeLevel: 'Pedagang',
      PortalId: 'Id portal',
      Distributor: 'Distributor',
      Diesel: 'Diesel',
      Gasoline: 'Bensin',
      OthersEV: 'Lainnya (EV)',
      Motorcycle: 'Sepeda motor',
      PassengerCars: 'Mobil Penumpang',
      TrucksBus: 'Truk/Bus',
      NoOfWorkbay: 'Jumlah Tempat Kerja',
      FillIn: 'Mengisi',
      Namecard: 'Kartu nama',
      TakeNamecard: 'Ambil Kartu Nama',
      CompanyShopfront: 'Etalase Perusahaan',
      PleaseUploadYourCompanyNamecard: 'Silakan unggah Kartu Nama Perusahaan Anda',
      TakePhotoOfCompanyShopfront: 'Ambil foto Etalase Perusahaan',
      PleaseUploadYourCompanyShopfront: 'Silakan unggah etalase Perusahaan Anda',
      SignUpForEXtraProgram: 'Daftar untuk program eXtra',
      PleaseCheckAgain: 'Silakan periksa lagi! ',
      IsTheGivenInformationCorrect: 'Apakah informasi yang diberikan benar?',
      DoYouAgreeOurPrivacyPolicy: 'Apakah Anda setuju dengan kebijakan privasi kami?',
      DoYouAgreeOurTermsConditions: 'Apakah Anda setuju dengan syarat & ketentuan kami?',
      ConfirmToRegister: 'Konfirmasi untuk Mendaftar',
      PleaseCheckIfTBD: 'Silakan periksa apakah informasi yang diberikan sudah benar?',
      Back: 'belakang',
      Confirm: 'Mengkonfirmasi',
      PleaseCheckIfTheGivenInformationIsCorrect: 'Silakan periksa apakah informasi yang diberikan sudah benar?',
      PleaseEnterTheRequiredInfo: 'Silakan masukkan info yang diperlukan',
      pleaseMakeSureIfYouWannaSignUpForEXtraProgram: 'pastikan jika Anda ingin mendaftar untuk program eXtra',
      AlreadySubmittedPendingApproval: 'Sudah dikirimkan, menunggu persetujuan!',
      OwnerMobileNo: 'Boss Mobile No. ',
      Manager: 'manajer',
      Employee: 'Karyawan',
      Owner: 'Bos',
      RequestANewOTP: 'Minta OTP baru',
      YouHaveSelectdSMSAsNotificationChannel: 'Anda telah memilih SMS sebagai saluran notifikasi, Anda akan segera menerima OTP ke nomor ponsel yang Anda berikan. silakan masukkan OTP untuk menyelesaikan pendaftaran Anda',
      DataProtectionGuidelines: 'Pedoman Perlindungan Data',
      GeneralTermsConditions: 'Syarat dan Ketentuan Umum',
      PleaseEnterYourPhoneNumber: 'Masukkan nomor telepon Anda',
      CellPhoneNumberIsRequired: 'Nomor ponsel diperlukan!',
      pleaseAgreeOurTermsAndConditions: 'Mohon setujui syarat dan ketentuan kami.',
      pleaseAgreeOurDataProtectionPolicy: 'mohon setujui kebijakan perlindungan data kami.',
      pleaseAgreeOurLegalNotice: 'mohon setujui Pemberitahuan Hukum kami.',
      PleaseChooseYourRegion: 'Silakan pilih wilayah Anda',
      PleaseEnterOTP: 'Silahkan masukkan OTP',
      LoginToProceed: 'Login untuk Melanjutkan',
      Login: 'Login',
      RegisterNow: 'Daftar Sekarang',
      DontHaveAnAccount: 'Tidak memiliki akun',
      OTP: 'OTP',
      RequestANewOTP: 'Minta OTP baru',
      YourApplicationIsPendingApproval: 'Aplikasi Anda sedang menunggu persetujuan, harap tunggu sebentar!',
      success: 'keberhasilan!',
      rule_list: 'Versi Sejarah',
      AccountData: 'Data Akun',
      OwnersFirstName: 'Nama Depan Bos',
      OwnersLastName: 'Nama Belakang Bos',
      CountryRegion: 'Negara / Wilayah',
      Role: 'Peran',
      ModifyBasicInformation: 'Foto perusahaan',
      PleaseFillInYourEmail: 'Format email Anda salah, silakan masukkan kembali!',
      CompanyData: 'Data perusahaan',
      eXtraID: 'ID eXtra',
      ModifyInformation: 'Ubah informasi',
      ModifyKeyInformation: 'Mengubah informasi kunci',
      ChangeCompanyPhoto: 'Ganti foto perusahaan',
      LogOut: 'Keluar',
      NoDataAvailable: 'Tidak ada data yang tersedia',
      Search: 'Mencari',
      Modify: 'Memodifikasi',
      PleaseCheckTheDataProtectionPolicyAndLegalNotice: 'Silakan periksa kebijakan perlindungan data dan pemberitahuan hukum!',

      // 人员管理:'',
      RoleManagement: 'Posisi Manajemen',
      Time: 'Waktu',
      Refuse: 'Menolak',
      Accept: 'Terima',
      DoYouConfirmToApproveIt: 'Apakah Anda mengonfirmasi untuk menyetujuinya?',
      DoYouConfirmToRejectIt: 'Apakah Anda mengonfirmasi untuk menolaknya?',
      PleaseConfirmAgain: 'Pemberitahuan',
      RoleInformation: 'Informasi peran',
      Name: 'Name',
      Activate: 'Mengaktifkan',
      Disable: 'Dinonaktifkan',
      IdentitySettings: 'Pengaturan identitas',
      DoYouConfirmToChangeThisInformation: 'Apakah Anda mengonfirmasi untuk mengubah informasi ini?',
      DoYouConfirmToActivateThisAccount: 'Apakah Anda mengonfirmasi untuk mengaktifkan akun ini?',
      DoYouConfirmToDisableThisAccount: 'Apakah Anda mengonfirmasi untuk menonaktifkan akun ini?',

      // article:'',
      PurchaseRecord: 'Catatan pembelian',
      TotalPurchase: 'Total pembelian',
      valueSGD: 'Nilai Jumlah Pembelian (IDR)',
      TotalPurchaseValue: 'Total nilai pembelian',
      TotalPurchaseAmt: 'Jumlah pembelian total',
      ProcurementRecords: 'Catatan pengadaan',
      View: 'Melihat',
      LatestArticles: 'Artikel Terbaru',
      LearnMore: 'Pelajari lebih lanjut >',

      // Autoparts:'',
      AutoParts: 'Suku Cadang Mobil',
      ViewMore: 'Lihat lebih banyak >',
      BoschGroup: 'Grup Bosch',
      BoschMobilityAftermarket: 'Bursa Mobilitas Bosch',

      // BSSList:'',
      WelcomeBoschModulePartner: 'Selamat Datang Mitra Modul Bosch',
      MyBenefits: 'Manfaat saya >',
      MoreInfoAboutModule: 'Info lebih lanjut. tentang Modul >',
      AnnualPartsTarget: 'Target suku cadang tahunan',
      StillToGo: 'Masih harus pergi',
      ContractTarget: 'Target kontrak',
      TotalAchievement: 'Total pencapaian',
      Year: 'tahun',
      ActualSales: 'Penjualan aktual',
      ContractPeriod: 'Masa Kontrak',
      years: 'tahun',
      JoinDate: 'Tanggal Mulai Kontrak',
      KeepUpTheGreatProgress: 'Pertahankan kemajuan besar!',
      DealerWillGetInTouchWithYouSoon: 'Dealer akan segera menghubungi Anda.',
      ViewMyContract: 'Lihat Kontrak Saya',
      Marketing: 'Pemasaran',
      promotionZone: 'Zona promosi',
      Attended: 'Sedang berjalan',
      Achieved: 'Bisa dikatakan',
      Done: 'Selesai',
      Received: 'Menerima',
      Abandoned: 'Ditinggalkan',
      to: 'ke',
      CurrentPurchase: 'Pembelian saat ini',
      SGD: 'IDR',
      MarketingPromotionZone: 'Zona promosi pemasaran',
      NotAttended: 'Tidak tersedia',

      // contact_us:'',
      Like: 'Suka',
      Comment: 'Komentar',
      SaySometihing: 'Katakan sesuatu',
      Sending: 'Kirim',
      Send: 'Dapatkan kode verifikasi',
      User: 'Pengguna',
      PleaseTypeWords: 'Silakan ketik kata-kata',
      AutomotiveTech: 'Teknologi Otomotif',
      views: 'Dilihat',

      // Home:'',
      FunctionalityInDevelopment: 'Fungsionalitas dalam pengembangan',

      // Module:'',
      Apply: 'aplikasi',
      JoinUsLogIn: "Bergabung dengan kami / Daftar masuk",
      JoinUs: 'Bergabunglah dengan kami',
      BoschModuleConcept: 'Konsep Bosch Module ',
      IHaveReadAndAcceptedTheBoschModulePolicy: 'Saya telah membaca dan menerima kebijakan Bosch module',
      SignNow: 'Daftar Sekarang',
      PleaseFillInTheBelowArea: 'Silahkan isi area di bawah ini',
      PleaseFillInYourFirstNameUnrecognizedOrIncorrectSignatureWillLeadToRegistrationFailure: 'Silakan isi nama depan Anda. Tanda tangan yang tidak dikenali atau salah akan menyebabkan kegagalan pendaftaran',
      PlaeaseFillInYourFirstName: 'Plaease isi nama depan Anda',
      pleaseCheckTheBoschModulePolicy: 'silakan periksa kebijakan Bosch Module ',
      KindlyProvideSignatureOrFullName: 'Mohon berikan tanda tangan atau nama lengkap',

      // MyAccount:'',
      loggedInSuccessfully: 'berhasil masuk!',
      IAlreadyHaveAnOTP: 'Saya sudah memiliki OTP',
      Tel: 'Telepon',
      PendingIdentityForReview: 'Identitas yang menunggu untuk ditinjau',
      EnterBoschModuleTiering: 'Masuk ke Tiering Bosch Module ',
      CurrentMember: 'Anggota saat ini',
      EarnPointsEveryDay: 'Dapatkan poin setiap hari',
      eXtraMall: 'Mal eXtra',
      eXtraPoints: 'Poin eXtra',
      eXtraPoint: 'Poin eXtra',
      Go: 'Pergi ',
      Redeem: 'Tebus',
      MyServices: 'Layanan Saya ',
      eXtra: 'eXtra',
      Order: 'Pesanan',
      Points: 'poin',
      Details: 'Rincian',
      Personal: 'Pribadi',
      Data: 'Data',
      Privacy: 'Privasi',
      Clause: 'Klausul',
      PersonalData: 'Data pribadi',
      eXtraWokrshopCandidate: 'Keanggotaan tertunda',
      KevinCarRepairServiceCenter: 'Kevin Car repair service center',
      MoreInfoAboutCompany: 'Info lebih lanjut tentang Perusahaan',
      MoreInfoAboutYou: 'Info selengkapnya tentang Anda',
      MyFirstName: 'Nama depan saya',
      MyLastName: 'Nama belakang saya',
      BossMobileNumber: 'Nomor ponsel bos',

      // Recordltem:'',
      time: 'Waktu',
      item: 'Produk',
      Qty: 'Jumlah',
      PN: 'PN',
      dealer: 'dealer',
      Download: 'mengunduh',

      // Rewards:'Imbalan ',
      expiresOn: 'kedaluwarsa pada',
      ActivityRules: 'Aturan aktivitas',
      CheckInDone: 'Check-in selesai',
      CheckInNOW: 'Check-in SEKARANG',

      // Video:'',
      FoundTheQRCode: 'Menemukan Kode QR',
      PleaseUseYourMobilePhoneToLog: 'Silakan gunakan ponsel Anda untuk masuk agar berhasil memindai produk!',
      PleaseAllowAuthorizedUseOfYourPhone: 'Harap izinkan penggunaan kamera ponsel Anda secara resmi dan pindai lagi!',

      // WhatsOn:'',
      ThoEvontTeasorGroupOllors: 'Berita apa yang ada',
      ThoEvontTeasorGroupOllors: 'Grup tampilan peristiwa memungkinkan beberapa peristiwa untuk ditandai dalam komponen',

      ThePointsDataWillUpdateMonthlyPleaseStayInformedWithUs: 'Data poin akan diperbarui setiap bulan. Harap tetap terinformasi bersama kami.',







      WorkRole: 'Rol Tugas',
      ICanNotFindMyDistributor: 'Tidak bisa menemukan dealer saya',
      AllAutoparts: 'Semua bagian Auto',
      BatterySpecialist: 'Baterai terkait',
      Others: '其他',
      Cancel: 'batalkan',
      PleaseEnterTheCorrectCompanyNameAndMobileNumber: 'Silakan masukkan nama perusahaan yang benar dan nomor telepon',
      ALL: 'seluruh',
      AccessOurFullRangeOfAvailableProductsOnTheGoSimplyClickOnYourProductGroupOfInterestToDiveIn: 'Akses seluruh gam produk yang tersedia kapanpun, dimanapun - hanya klik pada grup produk yang menarik Anda untuk belajar lebih!',
      searchPartnumberItem: 'Cari nomor bagian, item',
      searchPartnumberDealer: 'Cari nomor bagian, dealer',
      ModuleJoinData: 'Data modul',
      ViewMyContract: 'Lihat Kontrak Saya',
      NoContractHint: 'Kami belum menemukan kontrakmu.',
      NoticeOfExpirationOfContract: 'Kontrakmu sudah habis! Tolong tunggu Bosch untuk mengatur pembaruan.',

      Reset: 'Reset',
      Expired: 'habis',
      PleaseAccessThroughMobileBrowser: 'Silakan akses melalui browser ponsel',

      Enter: "Enter",
      OverallAnnualBoshPurchase:'Total pembelian tahunan Bosch',
    }
  },
  th: {
    // 泰国
    message: {
      PleaseCheckTheSignUpForEXtraProgram:'โปรดติ๊กและตกลงที่จะลงทะเบียนแพลตฟอร์ม eXtra!',
      monthlyOrderImport: 'นำเข้าคำสั่งรายเดือน',
      SKUOrderImport: 'นำเข้าคำสั่ง SKU',
      FileCode: 'การเข้ารหัสไฟล์',
      FileName: 'ชื่อแฟ้ม',
      OrderMonth: 'สั่งดวงจันทร์',
      importTime: 'เวลานำเข้า',
      Operate: 'การดำเนินงาน',
      importNow: 'นำเข้าทันที',
      DataFile: 'แฟ้มข้อมูล',
      ClickToUpload: 'คลิกเพื่ออัพโหลด',
      TemplateFile: 'แฟ้มเทมเพลต',
      clickToDownloadTheMonthlyOrderTemplateFile: 'คลิกที่นี่เพื่อดาวน์โหลดไฟล์เทมเพลตคำสั่งรายเดือน',
      clickToDownloadTheSKUOrderTemplateFile: 'คลิกเพื่อดาวน์โหลดไฟล์เทมเพลตคำสั่ง SKU',
      importData: 'นำเข้าข้อมูล',


      manualUploading: 'อัปโหลดด้วยตนเอง',
      PleaseFillInAllTheRequiredInformation: 'กรุณากรอกข้อมูลที่จำเป็นทั้งหมด!',
      Submit: 'ส่ง',
      ModulesSignUp: 'การลงทะเบียนโมดูล',
      Subject: 'หัวข้อ',
      Description: 'คำอธิบาย',
      InquiryDetails: 'สอบถามรายละเอียด',
      YourDetails: 'รายละเอียดของคุณ',
      ContactUs: 'ติดต่อเรา',
      DataProtectionNotice: 'ประกาศการคุ้มครองข้อมูล',
      IHaveTakenNoteOfThe: 'ผมสังเกตเห็น',
      YourRegistrationApplicationIsCurrentlyUnderReview: 'ขณะนี้อยู่ระหว่างการตรวจสอบใบสมัคร',
      ViewDetails: 'ดูรายละเอียด',
      AddEmployee: 'เพิ่มพนักงาน',
      PleaseRnterYourLoginAccount: 'ยื่นใบสมัครที่ร้านซ่อมบำรุงแล้วแจ้งให้เจ้านายทราบ',
      PendingApproval: 'เพื่อตรวจสอบ',
      AuditRejection: 'การตรวจสอบไม่ผ่าน',
      registered: 'ใช้งานอยู่',
      ResignedDisabled: 'ปิดการใช้งาน / ออกจากงาน',
      frozen: 'แช่แข็ง',
      Product: 'ผลิตภัณฑ์',
      StartTime: 'เวลาเริ่มต้น',
      EndTime: 'เวลาสิ้นสุด',
      Yes: 'ใช่',
      NO: 'ไม่',
      NotFilled: 'ไม่มีข้อมูล',
      OK: 'โอเค',
      ReceivedTheRewards: 'ได้รับรางวัลแล้ว',
      NotYetReceived: 'ยังไม่ได้รับ',
      YouHaveAchievedTheTarget: 'คุณบรรลุเป้าหมายแล้ว',
      PleaseAwaitBoschArrangementForYourEntitlement: 'โปรดรอจนกว่าบ๊อชจะจัดเตรียมสิทธิ์ของคุณ!',
      Congratulations: 'ขอแสดงความยินดี',
      ChannelPromotion: 'ช่องทางโปรโมชั่น',
      SelectAlanguage: 'เปลี่ยนภาษา',
      Home: 'หน้าแรก',
      WhatSon: 'กิจกรรม',
      Rewards: 'รางวัล eXtra',
      Module: 'โมดูล',
      TechWiki: 'ข่าวสารยานยนต์',
      MyAccount: 'บัญชีของฉัน',
      BOSCHAZPortal: 'ลูกค้า Bosch Autoparts',
      YourApplicationIsPendingReview: 'ใบสมัครของคุณอยู่ระหว่างการตรวจสอบ',
      CorporateInformation: 'ข้อมูลบริษัท',
      LegalNotice: 'ประกาศทางกฎหมาย',
      DataProtectionPolicy: 'นโยบายการคุ้มครองข้อมูล',
      PrivacySettings: 'การตั้งค่าความเป็นส่วนตัว',
      RobertBoschGmbH: '© 2021 - 2025 Robert Bosch GmbH, all rights reserved',

      // 选择国家页
      SelectCountry: 'เลือกประเทศ',
      WarmingReminderYouCanOnlyAccessMalyssiaNow: 'ปัจจุบันมีเพียงแพลตฟอร์มมาเลเซียของเราเท่านั้นที่สามารถเข้าถึงได้  โปรดติดตามการอัพเดตอีกครั้ง!',

      // 添加员工
      AddEmployee: 'เพิ่มพนักงาน',
      FillInEmployeeInfo: 'กรอกข้อมูลพนักงาน',
      SheHeIs: 'เธอ/เขาคือ....',
      YourApplicationIsAentToYourTheOwnerOfYourCompanyForApproval: 'ใบสมัครของคุณอยู่ระหว่างการตรวจสอบ',


      // 表单
      Registration: 'การลงทะเบียน',
      Step1: 'ขั้นตอนที่ 1',
      Step2: 'ขั้นตอนที่ 2',
      MobileNo: 'หมายเลขโทรศัพท์มือถือ',
      FirstName: 'ชื่อ',
      LastName: 'นามสกุล',
      CompanyName: 'ชื่อบริษัท',
      Email: 'อีเมล',
      EmailOptional: 'อีเมล (ไม่บังคับ)',
      FullAddress: 'ที่อยู่เต็ม',
      Address: 'ที่อยู่เต็ม',
      City: 'เมือง',
      PleaseEnter: 'กรุณากรอกรหัส',
      ProvinceState: 'จังหวัด/รัฐ',
      ZipCode: 'รหัสไปรษณีย์',
      Continue: 'ต่อไป',
      Step3: 'ขั้นตอนที่ 3',
      IAmA: 'ฉันเป็น......',
      IAm: 'ฉันเป็น......',
      RepairSegment: 'ส่วนซ่อม',
      VehicleSegment: 'กลุ่มยานพาหนะ',
      PreferredDistributor: 'ผู้จัดจําหน่ายที่ต้องการ',
      Workshop: 'อู่รถยนต์',
      Retailer: 'ร้านค้าปลีก',
      TradeLevel: 'ช่องทางการจำหน่ายสินค้า',
      PortalId: 'รหัสพอร์ทัล',
      Distributor: 'ผู้แทนจําหน่าย',
      Diesel: 'เครื่องยนต์ดีเซล',
      Gasoline: 'ระบบแก๊สโซลีน',
      OthersEV: 'อื่นๆ (EV)',
      Motorcycle: 'จักรยานยนต์',
      PassengerCars: 'รถยนต์นั่งส่วนบุคคล',
      TrucksBus: 'รถบรรทุก/รถบัส',
      NoOfWorkbay: 'จำนวนช่องซ่อม',
      FillIn: 'กรอก',
      Namecard: 'นามบัตร',
      TakeNamecard: 'รับนามบัตร',
      CompanyShopfront: 'หน้าร้านของบริษัท',
      PleaseUploadYourCompanyNamecard: 'โปรดอัปโหลดนามบัตรบริษัทของคุณ',
      TakePhotoOfCompanyShopfront: 'ถ่ายรูปหน้าร้านของบริษัท',
      PleaseUploadYourCompanyShopfront: 'โปรดอัปโหลดหน้าร้านของบริษัทของคุณ',
      SignUpForEXtraProgram: 'ลงทะเบียนสําหรับโปรแกรม eXtra',
      PleaseCheckAgain: 'โปรดตรวจสอบอีกครั้ง! ',
      IsTheGivenInformationCorrect: 'ข้อมูลที่ให้มาถูกต้องหรือไม่?',
      DoYouAgreeOurPrivacyPolicy: 'คุณเห็นด้วยกับนโยบายความเป็นส่วนตัวของเราหรือไม่?',
      DoYouAgreeOurTermsConditions: 'คุณเห็นด้วยกับข้อกําหนดและเงื่อนไขของเราหรือไม่?',
      ConfirmToRegister: 'การลงทะเบียน',
      PleaseCheckIfTBD: 'โปรดตรวจสอบว่าข้อมูลที่ให้มาถูกต้องหรือไม่?',
      Back: 'ย้อนกลับ',
      Confirm: 'ยืนยัน',
      PleaseCheckIfTheGivenInformationIsCorrect: 'โปรดตรวจสอบว่าข้อมูลที่ให้มาถูกต้องหรือไม่?',
      PleaseEnterTheRequiredInfo: 'กรุณากรอกข้อมูลที่จําเป็น',
      pleaseMakeSureIfYouWannaSignUpForEXtraProgram: 'โปรดตรวจสอบให้แน่ใจว่าคุณต้องการลงทะเบียนโปรแกรม eXtra ',
      AlreadySubmittedPendingApproval: 'ส่งแล้ว รอการอนุมัติ!',
      OwnerMobileNo: 'เบอร์โทรศัพท์มือถือ',
      Manager: 'ผู้จัดการ',
      Employee: 'ลูกจ้าง',
      Owner: 'นายจ้าง',
      RequestANewOTP: 'ขอรหัส OTP ใหม่',
      YouHaveSelectdSMSAsNotificationChannel: 'คุณได้เลือก SMS เป็นช่องทางการแจ้งเตือน คุณจะได้รับ OTP ไปยังหมายเลขโทรศัพท์มือถือที่คุณให้ไว้ในไม่ช้า กรุณากรอก OTP เพื่อลงทะเบียนให้เสร็จสมบูรณ์',
      DataProtectionGuidelines: 'นโยบายการคุ้มครองข้อมูล',
      GeneralTermsConditions: 'ข้อกําหนดและเงื่อนไขทั่วไป',
      PleaseEnterYourPhoneNumber: 'ต้องใช้หมายเลขโทรศัพท์มือถือ!',
      CellPhoneNumberIsRequired: 'ต้องใช้หมายเลขโทรศัพท์มือถือ!',
      pleaseAgreeOurTermsAndConditions: 'โปรดยอมรับข้อกําหนดและเงื่อนไขของเรา',
      pleaseAgreeOurDataProtectionPolicy: 'โปรดยอมรับนโยบายการคุ้มครองข้อมูลของเรา',
      pleaseAgreeOurLegalNotice: 'โปรดยอมรับประกาศทางกฎหมายของเรา',
      PleaseChooseYourRegion: 'โปรดเลือกภูมิภาคของคุณ',
      PleaseEnterOTP: 'กรุณากรอกรหัส OTP',
      LoginToProceed: 'เข้าสู่ระบบเพื่อดําเนินการต่อ',
      Login: 'เข้าสู่ระบบ',
      RegisterNow: 'ลงทะเบียนเลย',
      DontHaveAnAccount: 'ไม่มีบัญชี',
      OTP: 'รหัส OTP',
      RequestANewOTP: 'ขอรหัส OTP ใหม่',
      YourApplicationIsPendingApproval: 'ใบสมัครของคุณอยู่ระหว่างการอนุมัติโปรดรอสักครู่!',
      success: 'ความสําเร็จ!',
      rule_list: 'ประวัติ',
      AccountData: 'ข้อมูลบัญชี',
      OwnersFirstName: 'ชื่อเจ้าของบริษัท',
      OwnersLastName: 'นามสกุลเจ้าของบริษัท',
      CountryRegion: 'ประเทศ / ภูมิภาค',
      Role: 'บทบาทงาน',
      ModifyBasicInformation: 'ภาพถ่าย บริษัท',
      PleaseFillInYourEmail: 'อีเมลของคุณไม่ถูกต้องในรูปแบบโปรดป้อนอีกครั้ง!',
      CompanyData: 'ข้อมูลบริษัท',
      eXtraID: 'รหัส eXtra',
      ModifyInformation: 'แก้ไขข้อมูล',
      ModifyKeyInformation: 'แก้ไขข้อมูลสําคัญ',
      ChangeCompanyPhoto: 'เปลี่ยนรูปบริษัท',
      LogOut: 'ออกจากระบบ',
      NoDataAvailable: 'ไม่มีข้อมูล',
      Search: 'ค้นหา',
      Modify: 'แก้ไข',
      PleaseCheckTheDataProtectionPolicyAndLegalNotice: 'โปรดตรวจสอบนโยบายการคุ้มครองข้อมูลและประกาศทางกฎหมาย! ',

      // 人员管理
      RoleManagement: 'การจัดการบทบาท',
      Time: 'เวลา',
      Refuse: 'ปฏิเสธ',
      Accept: 'ยอมรับ',
      DoYouConfirmToApproveIt: 'คุณยืนยันที่จะอนุมัติหรือไม่?',
      DoYouConfirmToRejectIt: 'คุณยืนยันที่จะปฏิเสธหรือไม่?',
      PleaseConfirmAgain: 'การแจ้งเตือน',
      RoleInformation: 'ข้อมูลเพิ่มเติม',
      Name: 'ชื่อ',
      Activate: 'เปิดบัญชี',
      Disable: 'ปิดบัญชี',
      IdentitySettings: 'การตั้งค่าข้อมูลส่วนตัว',
      DoYouConfirmToChangeThisInformation: 'คุณยืนยันที่จะเปลี่ยนแปลงข้อมูลนี้หรือไม่?',
      DoYouConfirmToActivateThisAccount: 'คุณยืนยันที่จะเปิดใช้งานบัญชีนี้หรือไม่?',
      DoYouConfirmToDisableThisAccount: 'คุณยืนยันที่จะปิดใช้งานบัญชีนี้หรือไม่?',

      // article
      PurchaseRecord: 'บันทึกการซื้อ',
      TotalPurchase: 'ยอดคำสั่งซื้อ',
      valueSGD: 'มูลค่า (บาท)',
      TotalPurchaseValue: 'มูลค่าการซื้อทั้งหมด',
      TotalPurchaseAmt: 'จำนวนทั้งหมด',
      ProcurementRecords: 'ประวัติคำสั่งซื้อ',
      View: 'ภาพรวม',
      LatestArticles: 'บทความล่าสุด',
      LearnMore: 'ศึกษาเพิ่มเติม >',

      // Autoparts
      AutoParts: 'อะไหล่รถยนต์',
      ViewMore: 'ดูเพิ่มเติม >',
      BoschGroup: 'Bosch Group',
      BoschMobilityAftermarket: 'Bosch ฝ่ายอะไหล่รถยนต์',

      // BSSList
      WelcomeBoschModulePartner: 'ยินดีต้อนรับพันธมิตรModule!',
      MyBenefits: 'สิทธิประโยชน์ของฉัน >',
      MoreInfoAboutModule: 'ข้อมูลเพิ่มเติมเกี่ยวกับModule >',
      AnnualPartsTarget: 'เป้าหมายประจําปี',
      StillToGo: 'เป้าหมายที่ต้องไปถึง',
      ContractTarget: 'เป้าหมายสัญญา',
      TotalAchievement: 'ความสําเร็จทั้งหมด',
      Year: 'ปี',
      ActualSales: 'ยอดขายจริง',
      ContractPeriod: 'ระยะเวลาสัญญา ',
      years: 'ปี',
      JoinDate: 'วันที่เริ่มต้นสัญญา',
      KeepUpTheGreatProgress: 'ติดตามความคืบหน้า',
      DealerWillGetInTouchWithYouSoon: 'ตัวแทนจําหน่ายจะติดต่อคุณในภายหลัง',
      ViewMyContract: 'ดูสัญญาของฉัน',
      Marketing: 'การตลาด',
      promotionZone: 'โซนโปรโมชั่น',
      Attended: 'กำลังดำเนินการ',
      AchievedDone: 'เสร็จสิ้น',
      Received: 'ยอมรับ',
      Abandoned: 'ปฏิเสธ',
      to: 'ถึง',
      CurrentPurchase: 'คำสั่งซื้อล่าสุด',
      SGD: 'บาท',
      MarketingPromotionZone: 'โปรโมชั่นสินค้า',
      NotAttended: 'ไม่มีบริการ',

      // contact_us
      Like: 'ชอบ',
      Comment: 'ติชม',
      SaySometihing: 'ติดต่อเรา',
      Sending: 'ส่ง',
      SendUser: 'ผู้ใช้',
      Send: 'ส่ง',
      PleaseTypeWords: 'กรอกที่นี่',
      AutomotiveTech: 'เทคโนโลยียานยนต์',
      views: 'จำนวนผู้เข้าชม',

      // Home
      FunctionalityInDevelopment: 'ฟังก์ชันกำลังพัฒนา',

      // Module
      Apply: 'สมัคร',
      JoinUsLogIn: "เข้าร่วมกับเรา / เข้าสู่ระบบ",
      JoinUs: 'เข้าร่วมกับเรา',
      BoschModuleConcept: 'แนวคิดโมดูลของ Bosch',
      IHaveReadAndAcceptedTheBoschModulePolicy: 'ฉันได้อ่านและยอมรับนโยบายโมดูลของ Bosch แล้ว',
      SignNow: 'ลงชื่อเลย',
      PleaseFillInTheBelowArea: 'กรุณากรอกข้อมูลในส่วนด้านล่าง',
      PleaseFillInYourFirstNameUnrecognizedOrIncorrectSignatureWillLeadToRegistrationFailure: 'คำเตือน กรุณากรอกชื่อของคุณให้ถูกต้อง',
      PlaeaseFillInYourFirstName: 'กรอกชื่อของคุณ',
      pleaseCheckTheBoschModulePolicy: 'โปรดตรวจสอบนโยบายโมดูลของ Bosch',
      KindlyProvideSignatureOrFullName: 'กรุณาระบุลายเซ็นหรือชื่อ-นามสกุล',

      // MyAccount
      loggedInSuccessfully: 'สําเร็จ!',
      IAlreadyHaveAnOTP: 'ฉันมีรหัส OTP อยู่แล้ว',
      Tel: 'หมายเลขโทรศัพท์มือถือ',
      PendingIdentityForReview: 'อยู่ในขั้นตอนการตรวจสอบ',
      EnterBoschModuleTiering: 'เข้าสู่การจัดระดับโมดูลของ Bosch >',
      CurrentMember: 'สมาชิกปัจจุบัน',
      EarnPointsEveryDay: 'รับคะแนนทุกวัน',
      eXtraMall: 'eXtra Mall',
      eXtraPoints: 'คะแนน eXtra',
      eXtraPoint: 'คะแนน eXtra',
      Go: 'ไป',
      Redeem: 'แลกของรางวัล',
      MyServices: 'บริการของฉัน',
      eXtra: 'eXtra',
      Order: 'คำสั่งซื้อ',
      Points: 'คะแนน',
      Details: 'ราย ละเอียด',
      Personal: 'ข้อมูลส่วนบุคคล',
      Data: 'ข้อมูล',
      Privacy: 'ความเป็นส่วนตัว',
      Clause: 'อนุประโยค',
      PersonalData: 'ข้อมูลส่วนบุคคล',
      eXtraWokrshopCandidate: 'รอการเป็นสมาชิก',
      KevinCarRepairServiceCenter: '-',
      MoreInfoAboutCompany: 'ข้อมูลเพิ่มเติมเกี่ยวกับ บริษัท',
      MoreInfoAboutYou: 'ข้อมูลเพิ่มเติมเกี่ยวกับคุณ',
      MyFirstName: 'ชื่อของฉัน',
      MyLastName: 'นามสกุลของฉัน',
      BossMobileNumber: 'หมายเลขโทรศัพท์มือถือของคุณ',

      // Recordltem
      time: 'เวลา',
      item: 'รายการ',
      Qty: 'จำนวน',
      PN: 'เบอร์สินค้า',
      dealer: 'ตัวแทนจําหน่าย',
      Download: 'ดาวน์โหลด',

      // Rewards:'รางวัล',
      expiresOn: 'หมดอายุวันที่',
      ActivityRules: 'รายละเอียดกิจกรรม',
      CheckInDone: 'เช็คอินเสร็จแล้ว',
      CheckInNOW: 'เช็คอินตอนนี้',

      // Video
      FoundTheQRCode: 'พบรหัส QR',
      PleaseUseYourMobilePhoneToLog: 'โปรดใช้โทรศัพท์มือถือของคุณเพื่อเข้าสู่ระบบเพื่อสแกนผลิตภัณฑ์ให้สําเร็จ!',
      PleaseAllowAuthorizedUseOfYourPhone: 'โปรดอนุญาตให้ใช้กล้องโทรศัพท์ของคุณและสแกนอีกครั้ง!',

      // WhatsOn
      ThoEvontTeasorGroupOllors: 'มีอะไรใหม่ใน Bosch',
      ThoEvontTeasorGroupOllors: 'มีอะไรใหม่ใน Bosch',

      ThePointsDataWillUpdateMonthlyPleaseStayInformedWithUs: 'ข้อมูลคะแนนจะได้รับการอัปเดตทุกเดือนโปรดติดตาม!',







      WorkRole: 'บทบาทการทำงาน',
      ICanNotFindMyDistributor: 'ไม่พบตัวแทนจำหน่าย',
      AllAutoparts: 'ทั้งหมด',
      BatterySpecialist: 'แบตเตอรี่ที่เกี่ยวข้อง',
      Others: 'อื่นๆ',
      Cancel: 'การยกเลิก',
      PleaseEnterTheCorrectCompanyNameAndMobileNumber: 'กรุณากรอกชื่อบริษัทและหมายเลขโทรศัพท์มือถือที่ถูกต้อง',
      ALL: 'ทั้งหมด',
      AccessOurFullRangeOfAvailableProductsOnTheGoSimplyClickOnYourProductGroupOfInterestToDiveIn: 'เข้าถึงผลิตภัณฑ์ที่หลากหลายของเราได้ทุกที่ทุกเวลา - เพียงแค่คลิกที่กลุ่มผลิตภัณฑ์ที่คุณสนใจเพื่อดูข้อมูลเชิงลึก!',
      searchPartnumberItem: 'ค้นหาหมายเลขสินค้า ชื่อสินค้า',
      searchPartnumberDealer: 'ค้นหาหมายเลขสินค้า, ตัวแทนจำหน่าย',
      ModuleJoinData: 'ข้อมูล Module',
      ViewMyContract: 'ดูสัญญาของฉัน',
      NoContractHint: 'เรายังไม่ได้สอบถามเกี่ยวกับสัญญาของคุณ โปรดติดต่อฝ่ายขายบ๊อชของคุณ',
      NoticeOfExpirationOfContract: 'สัญญาของคุณหมดอายุแล้ว! โปรดรอให้บ๊อชจัดการต่อสัญญา',

      Reset: 'รีเซ็ต',
      Expired: 'หมดอายุ',
      PleaseAccessThroughMobileBrowser: 'กรุณาเข้าเบราว์เซอร์โทรศัพท์มือถือ',

      Enter: "เข้า",
      Partnership: 'ความร่วมมือ',
      EGGMALL: 'เอ็ก มอลล์',
      CreateTheEGGMallAccount:'สร้างบัญชี EGG Mall',
      Egg_hint:'คุณจะอนุญาตให้เราใช้ข้อมูลของคุณเพื่อสร้างบัญชี Egg Mall ซึ่งจะผูกกับบัญชีของเรา.',
      Egg_check:'ฉันยอมรับนโยบายความเป็นส่วนตัวของ EGG Mall',
      IAccept:'I ยอมรับ',
      Egg_check_Policy:'《นโยบายความเป็นส่วนตัวของ EGG Mall》',
      ConfirmToCreate:'ยืนยันการสร้าง',
      PleaseCheckAndAcceptThePrivacyPolicy:'โปรดตรวจสอบและยอมรับนโยบายความเป็นส่วนตัว',
      CreationComplete:'สร้างเสร็จสมบูรณ์',
      OverallAnnualBoshPurchase:'การซื้อของ Bosch ทั้งปี',
    }
  },
  my:{
    // 马来西亚
    message: {
      PleaseCheckTheSignUpForEXtraProgram:'请勾选并同意注册eXtra平台！',
      monthlyOrderImport:'导出月度订单',
      SKUOrderImport:'导入SKU订单',
      FileCode:'文件编号',
      FileName:'文件名',
      OrderMonth:'订单月份',
      importTime:'导入时间',
      Operate:'操作',
      importNow:'立即导入',
      DataFile:'数据文件',
      ClickToUpload:'点击上传',
      TemplateFile:'模板文件',
      clickToDownloadTheMonthlyOrderTemplateFile:'点击下载《月度订单模板文件》',
      clickToDownloadTheSKUOrderTemplateFile:'点击下载《SKU订单模板文件》',
      importData:'导入数据',
      manualUploading:'手动上传',
      Home:'首页',
      WhatSon:'资讯',
      Rewards:'奖励',
      Module:'Module',
      TechWiki:'技术百科',
      MyAccount:'我的',
      BOSCHAZPortal:'Bosch Business Portal',
      YourApplicationIsPendingReview:'你的申请正在审核中。',
      CorporateInformation:'公司信息',
      LegalNotice:'法律声明',
      DataProtectionPolicy:'数据保护政策',
      PrivacySettings:'隐私设置',
      RobertBoschGmbH:'©2025 版权所有 罗伯特·博世有限公司 保留所有权利',
      // 选择国家页:'',
      SelectCountry:'选择国家',
      WarmingReminderYouCanOnlyAccessMalyssiaNow:'您目前无法访问该国家，即将开放敬请期待！',
      // 添加员工:'',
      AddEmployee:'添加员工',
      FillInEmployeeInfo:'填写员工信息',
      SheHeIs:'她/他是',
YourApplicationIsAentToYourTheOwnerOfYourCompanyForApproval:'您的申请已递交给您所选公司的拥有者。',
      // 表单:'',
      Registration:'注册流程',
      Step1:'第1步骤',
      Step2:'第2步骤',
      MobileNo:'手机号码',
      FirstName:'名字',
      LastName:'姓氏',
      CompanyName:'公司名称',
      Email:'邮箱',
      EmailOptional:'邮箱（可选项）',
      FullAddress:'详细地址',
      Address:'地址',
      City:'城市',
      PleaseEnter:'请输入',
      ProvinceState:'省份/州/邦',
      ZipCode:'邮编',
      Continue:'下一步',
      Step3:'第3步骤',
      IAmA:'我是…...',
      Iam:'我是……',
      RepairSegment:'维修站类型',
      VehicleSegment:'车型细分',
      PreferredDistributor:'首选经销商',
      Workshop:'维修站',
      Retailer:'分销商',
      TradeLevel:'客户类型',
      PortalId:'Portal ID',
      Distributor:'经销商',
      Diesel:'柴油',
      Gasoline:'汽油',
      OthersEV:'其他（新能源车）',
      Motorcycle:'摩托车',
      PassengerCars:'乘用车',
      TrucksBus:'卡车/公交车',
      NoOfWorkbay:'工位数量',
      FillIn:'填写',
      Namecard:'名片',
      TakeNamecard:'拍摄名片',
      CompanyShopfront:'公司门头',
      PleaseUploadYourCompanyNamecard:'请上传你的公司名片',
      TakePhotoOfCompanyShopfront:'拍摄公司门头',
      PleaseUploadYourCompanyShopfront:'请上传你的公司门头',
      SignUpForEXtraProgram:'注册eXtra会员',
      PleaseCheckAgain:'请再次确认。',
      IsTheGivenInformationCorrect:'请问所提供信息是否正确？',
      DoYouAgreeOurPrivacyPolicy:'请问您同意我们的隐私政策吗？',
      DoYouAgreeOurTermsConditions:'请问您同意我们的使用条款吗？',
      ConfirmToRegister:'确认注册',
      PleaseCheckIfTBD:'请您确认所提供信息是否正确！',
      Back:'返回',
      Confirm:'确认',
      PleaseCheckIfTheGivenInformationIsCorrect:'请您确认所提供信息是否正确！',
      PleaseEnterTheRequiredInfo:'请输入必填信息。',
      pleaseMakeSureIfYouWannaSignUpForEXtraProgram:'请您确认是否要同步注册eXtra会员。',
      AlreadySubmittedPendingApproval:'申请已提交，正在审核中。',
      OwnerMobileNo:'老板手机号码',
      Manager:'经理',
      Employee:'员工',
      Owner:'负责人',
      RequestANewOTP:'请输入新的验证码。',
      YouHaveSelectdSMSAsNotificationChannel:'您已选择短信作为通知方式。您所提供的手机号码将收到1条验证码短信。请输入该验证码用以完成注册。',
      DataProtectionGuidelines:'数据保护指南',
      GeneralTermsConditions:'使用条款',
      PleaseEnterYourPhoneNumber:'请您输入手机号码。',
      CellPhoneNumberIsRequired:'手机号码是必填项。',
      pleaseAgreeOurTermsAndConditions:'请您确认我们的使用协议。',
      pleaseAgreeOurDataProtectionPolicy:'请您确认我们的数据保护政策。',
      pleaseAgreeOurLegalNotice:'请您确认我们的法律声明。',
      PleaseChooseYourRegion:'请您选择你所在地区。',
      PleaseEnterOTP:'请你输入验证码。',
      LoginToProceed:'登录',
      Login:'登录',
      RegisterNow:'立即注册',
      DontHaveAnAccount:'未找到该账号',
      OTP:'验证码',
      RequestANewOTP:'获取新的验证码',
      YourApplicationIsPendingApproval:'您的申请正在审核中，请耐心等待！',
      success:'成功！',
      rule_list:'历史版本',
      AccountData:'账号信息',
      CountryRegion:'国家/地区',
      Role:'角色',
      ModifyBasicInformation:'公司图片',
      PleaseFillInYourEmail:'您邮箱格式不正确，请重新输入！',
      CompanyData:'公司信息',
      eXtraID:'eXtra ID',
      ModifyInformation:'编辑信息',
      ModifyKeyInformation:'编辑关键信息',
      ChangeCompanyPhoto:'更新公司图片',
      LogOut:'登出',
      NoDataAvailable:'未查询到资料',
      Search:'查询',
      Modify:'编辑',
      PleaseCheckTheDataProtectionPolicyAndLegalNotice:'请您确认数据保护政策和法律声明！',
      // 人员管理:'',
      RoleManagement:'角色管理',
      Time:'加入时间',
      Refuse:'拒绝',
      Accept:'接受',
      DoYouConfirmToApproveIt:'请问您确认同意吗？',
      DoYouConfirmToRejectIt:'请问您确认拒绝吗？',
      PleaseConfirmAgain:'通知',
      RoleInformation:'角色信息',
      Name:'名称',
      Activate:'激活',
      Disable:'禁用',
      IdentitySettings:'身份设置',
      DoYouConfirmToChangeThisInformation:'请问您确认修改信息吗？',
      DoYouConfirmToActivateThisAccount:'请问您确认激活该账号吗？',
      DoYouConfirmToDisableThisAccount:'请问您确认禁用该账号吗？',
      // article:'',
      PurchaseRecord:'采购记录',
      TotalPurchase:'总采购',
      valueSGD:'金额（新加坡币）',
      TotalPurchaseValue:'总采购金额',
      TotalPurchaseAmt:'总采购数量',
      ProcurementRecords:'采购记录',
      View:'查看',
      LatestArticles:'最新资讯',
      LearnMore:'查看更多 >',
      // Autoparts:'',
      AutoParts: "汽车配件",
      ViewMore: "查看更多 >",
      BoschGroup: "博世集团",
      BoschMobilityAftermarket: "博世汽车售后市场",
      // BSSList:'',
      WelcomeBoschModulePartner:'欢迎博世Module合作伙伴！',
      MyBenefits:'我的权益 >',
      MoreInfoAboutModule:'更多关于Module信息 >',
      AnnualPartsTarget:'年度采购目标',
      StillToGo:'未完成',
      ContractTarget:'合同目标',
      TotalAchievement:'整体达成',
      Year:'年度采购目标',
      ActualSales:'实际销售额',
      ContractPeriod:'合同年限',
      years:'年',
      JoinDate:'合同开始时间',
      KeepUpTheGreatProgress:'请继续保持巨大进步！',
      DealerWillGetInTouchWithYouSoon:'经销商将尽快与您联系。',
      ViewMyContract:'查看我的合同',
      Marketing:'市场',
      promotionZone:'支持活动',
      Attended:'已参与',
      Achieved:'已达成',
      Received:'已领取',
      Done:'完成',
      Abandoned:'已放弃',
      to:'To',
      CurrentPurchase:'当前采购',
      SGD:'新加坡币',
      MarketingPromotionZone:'市场支持活动',
      NotAttended:'不可用',
      // contact_us:'',
      Like:'点赞',
      Comment:'评论',
      SaySometihing:'请输入文案',
      Sending:'发送',
      User:'用户',
      PleaseTypeWords:'请输入文案',
      AutomotiveTech:'技术百科',
      views:'观看量',
      // Home:'',
      FunctionalityInDevelopment:'功能正在开发中。',
      // Module:'',
      Apply:'提交',
      JoinUsLogIn:'加入我们/登录',
      JoinUs:'加入我们',
      BoschModuleConcept:'Bosch module concept',
      IHaveReadAndAcceptedTheBoschModulePolicy:'我已确认并同意博世Module政策。',
      SignNow:'立即签名',
      PleaseFillInTheBelowArea:'请完善以下区域内容。',
      PleaseFillInYourFirstNameUnrecognizedOrIncorrectSignatureWillLeadToRegistrationFailure:'请填写您的名字，若签名无法识别或不正确的话，将导致本次注册申请失败。',
      PlaeaseFillInYourFirstName:'请输入您的名称',
      pleaseCheckTheBoschModulePolicy:'请确认博世Module政策',
      KindlyProvideSignatureOrFullName:'请输入您的全名',
      // MyAccount:'',
      loggedInSuccessfully:'登录成功！',
      IAlreadyHaveAnOTP:'我已收到验证码。',
      Tel:'电话号码',
      PendingIdentityForReview:'申请待审核',
      EnterBoschModuleTiering:'输入博世Module等级',
      CurrentMember:'当前成员',
      EarnPointsEveryDay:'天天赢积分',
      eXtraMall:'eXtra商城',
      eXtraPoints:'eXtra积分',
      eXtraPoint:'eXtra积分',
      Go:'前往',
      Redeem:'兑换',
      MyServices:'我的服务',
      eXtra:'eXtra',
      Order:'订单',
      Points:'eXtra积分',
      Details:'详情',
      Personal:'个人',
      Data:'数据',
      Privacy:'隐私',
      Clause:'条款',
      PersonalData:'个人数据',
      eXtraWokrshopCandidate:'会员',
      MoreInfoAboutCompany:'更多公司信息',
      MoreInfoAboutYou:'更多你的信息',
      MyFirstName:'我的名字',
      MyLastName:'我的姓氏',
      BossMobileNumber:'老板手机号码',
      // Recordltem:'',
      time:'时间',
      item:'类目',
      Qty:'数量',
      PN:'产品编号',
      dealer:'经销商',
      Download:'下载',
      // Rewards:'',
      expiresOn:'',
      ActivityRules:'活动规则',
      CheckInDone:'已签到',
      CheckInNOW:'立即签到',
      ThePointsDataWillUpdateMonthlyPleaseStayInformedWithUs:'积分数据将以月度频次进行更新，请您耐心等待。',
      // Video:'',
      FoundTheQRCode:'已找到二维码',
      PleaseUseYourMobilePhoneToLog:'请您使用您的手机进行登录以便于进行产品扫码！',
      PleaseAllowAuthorizedUseOfYourPhone:'请您允许手机摄像头的使用权限并再次扫码。',
      //刘新增 10/10:'',
      ModuleJoinData:'Module加入时间',
      ViewMyContract:'查看我的合同',
      NoContractHint:'我们未找到您的合同，请您联系博世销售。',
      NoticeOfExpirationOfContract:'您的合同已到期！请您等待博世联系续约！',
      ChannelPromotion:'渠道市场支持',
      OK:'好的',
      Congratulations:'恭喜！',
      YouHaveAchievedTheTarget:'您已达成任务目标！',
      PleaseAwaitBoschArrangementForYourEntitlement:'请您耐心等待博世为您发放权益。',
      ReceivedTheRewards:'已收到奖励',
      NotYetReceived:'还未收到',
      // (10/12):'',
      WorkRole:'员工角色',
      ICanNotFindMyDistributor:'未找到我的经销商',
      AllAutoparts:'全产品线',
      BatterySpecialist:'蓄电池专家',
      Others:'其他',
      Cancel:'取消',
      PleaseEnterTheCorrectCompanyNameAndMobileNumber:'请您输入正确的公司名称和手机号码',
      ALL:'全部',
      AccessOurFullRangeOfAvailableProductsOnTheGoSimplyClickOnYourProductGroupOfInterestToDiveIn:'随时随地访问我们的全系列可用产品，只需点击您感兴趣的产品组即可深入了解！',
      searchPartnumberItem:'查询产品编号、类目',
      searchPartnumberDealer:'查询产品编号、经销商',
      Send:'发送',
      Submit:'提交',
      BoschAutopartsCustomerPortal:'Bosch Business Portal',
      //(10/14):'',
      Reset:'重置',
      SelectAlanguage:'选择语言',
      NotFilled:'未找到',
      Yes:'是',
      NO:'否',
      StartTime:'开始时间',
      EndTime:'结束时间',
      Product:'产品',
      PendingApproval:'待审核',
      AuditRejection:'拒绝申请',
      registered:'已注册',
      ResignedDisabled:'已离职/已禁用',
      frozen:'已冻结',
      PleaseRnterYourLoginAccount:'请您输入登录账号',
      AddEmployee:'添加员工',
      ViewDetails:'查看详情',
      YourRegistrationApplicationIsCurrentlyUnderReview:'您的注册申请正在审核中。',
      IHaveTakenNoteOfThe:'我已阅读并同意',
      DataProtectionNotice:'《数据保护政策》',
      ContactUs:'联系我们',
      YourDetails:'咨询人信息',
      InquiryDetails:'咨询内容',
      Description:'详细描述',
      Subject:'主题',
      ModulesSignUp:'Modules 注册',
      PleaseFillInAllTheRequiredInformation:'请填写所有要求信息',
      Expired:'已到期',
      PleaseAccessThroughMobileBrowser:'请通过手机浏览器访问。',
      Enter:'输入',
      Partnership:'合作伙伴',
      EGGMALL:'EGG MALL',
      CreateTheEGGMallAccount:'创建EGG MALL平台账号',
      Egg_hint:'您将授权我们使用您的信息创建Egg Mall帐户，且该Egg Mall帐户将与我们平台的帐户进行绑定。',
      Iaccept:'我已阅读并同意',
      Egg_check_Policy:'《EGG Mall 隐私政策》',
      ConfirmToCreate:'确认创建',
      PleaseCheckAndAcceptThePrivacyPolicy:'请您阅读并同意隐私政策',
      CreationComplete:'创建成功！',
      OverallAnnualBoshPurchase:'博世年度采购总额',
    }
  },
  zh: {
    // 中文
    message: {
      PleaseCheckTheSignUpForEXtraProgram:'请勾选并同意注册eXtra平台！',
      monthlyOrderImport: '月度订单导入',
      SKUOrderImport: 'SKU订单导入',
      FileCode: '文件代码',
      FileName: '文件名',
      OrderMonth: '订单月份',
      importTime: '导入时间',
      Operate: '操作',
      importNow: '立即导入',
      DataFile: '数据文件',
      ClickToUpload: '点击上传',
      TemplateFile: '模板文件',
      clickToDownloadTheMonthlyOrderTemplateFile: '单击此处下载月度订单模板文件',
      clickToDownloadTheSKUOrderTemplateFile: '点击下载SKU订单模板文件',
      importData: '导入数据',


      manualUploading: '手动上传',
      Expired: '到期',
      PleaseFillInAllTheRequiredInformation: '请填写所有必填信息！',
      Submit: '提交',
      ModulesSignUp: '模块注册',
      Subject: '主题',
      Description: '描述',
      InquiryDetails: '查询详情',
      YourDetails: '您的详细信息',
      ContactUs: '联系我们',
      DataProtectionNotice: '数据保护通知',
      IHaveTakenNoteOfThe: '我注意到',
      YourRegistrationApplicationIsCurrentlyUnderReview: '您的注册申请目前正在审核中。',
      ViewDetails: '查看详细信息',
      AddEmployee: '添加员工',
      PleaseRnterYourLoginAccount: '申请已在维修店提交，请通知老板审核',
      PendingApproval: '待审核',
      AuditRejection: '审核不通过',
      registered: '活跃',
      ResignedDisabled: '禁用/离职',
      frozen: '冻结',
      Product: '产品',
      StartTime: '开始时间',
      EndTime: '结束时间',
      Yes: '是',
      NO: '否',
      NotFilled: '无数据',
      OK: '好的',
      ReceivedTheRewards: '已收到奖励',
      NotYetReceived: "尚未收到",
      YouHaveAchievedTheTarget: "你已经达到了目标。",
      PleaseAwaitBoschArrangementForYourEntitlement: '请等待博世安排您的权利！',
      Congratulations: '恭喜',
      ChannelPromotion: '渠道推广',
      SelectAlanguage: '切换语言',
      Home: "首页",
      WhatSon: "新闻和活动",
      Rewards: "奖励",
      Module: "配件优选店",
      TechWiki: "汽车技术",
      MyAccount: "我的",
      BOSCHAZPortal: "博世亚太区一站式采购平台",
      YourApplicationIsPendingReview: "您的申请正在等待审核",
      CorporateInformation: "企业信息",
      LegalNotice: "法律声明",
      DataProtectionPolicy: "数据保护政策",
      PrivacySettings: "隐私设置",
      RobertBoschGmbH: "© 2021 - 2025 博世有限公司，版权所有",

      // 选择国家页
      SelectCountry: "选择国家",
      WarmingReminderYouCanOnlyAccessMalyssiaNow: "您现在只能访问Malyssia。其他国家即将到来！请随时与我们联系",

      // 添加员工
      AddEmployee: "添加员工",
      FillInEmployeeInfo: "填写员工信息",
      SheHeIs: "身份",
      YourApplicationIsAentToYourTheOwnerOfYourCompanyForApproval: "您的申请已开始进行审批。",


      // 表单
      Registration: "登记",
      Step1: "第1步",
      Step2: "第2步",
      MobileNo: "手机",
      FirstName: "名",
      LastName: "姓",
      CompanyName: "公司名称",
      Email: "电子邮件",
      EmailOptional: "电子邮件（可选）",
      FullAddress: "完整地址",
      Address: "地址",
      City: "城市",
      PleaseEnter: "请输入",
      ProvinceState: "省/州",
      ZipCode: "邮编",
      Continue: "继续",
      Step3: "第3步",
      IAmA: "我是",
      IAm: "我是",
      RepairSegment: "部门",
      VehicleSegment: "汽车细分市场",
      PreferredDistributor: "经销商",
      Workshop: "工作站",
      Retailer: "零售商",
      TradeLevel: "等级",
      PortalId: "我的ID",
      Distributor: "经销商",
      Diesel: "柴油机",
      Gasoline: "汽油",
      OthersEV: "其他（EV）",
      Motorcycle: "摩托车",
      PassengerCars: "乘用车",
      TrucksBus: "卡车/大巴车",
      NoOfWorkbay: "工作站编号",
      FillIn: "填写",
      Namecard: "名片",
      TakeNamecard: "名片",
      CompanyShopfront: "公司店面",
      PleaseUploadYourCompanyNamecard: "您的公司信息",
      TakePhotoOfCompanyShopfront: "上传公司店面照片",
      PleaseUploadYourCompanyShopfront: "请上传公司店面照片",
      SignUpForEXtraProgram: "注册eXtra",
      PleaseCheckAgain: "请检查必填信息！",
      IsTheGivenInformationCorrect: "请确认所提供的信息是否准确？",
      DoYouAgreeOurPrivacyPolicy: "您同意我们的隐私政策吗？",
      DoYouAgreeOurTermsConditions: "您同意我们的条款和条件吗？",
      ConfirmToRegister: "确认注册",
      PleaseCheckIfTBD: "请核实所提供的信息是否准确无误？",
      Back: "返回",
      Confirm: "确认",
      PleaseCheckIfTheGivenInformationIsCorrect: "请核实所提供的信息是否准确无误？",
      PleaseEnterTheRequiredInfo: "请输入所需信息。",
      pleaseMakeSureIfYouWannaSignUpForEXtraProgram: "请确认注册eXtra",
      AlreadySubmittedPendingApproval: "已提交，等待审批！",
      OwnerMobileNo: "老板手机",
      Manager: "经理",
      Employee: "员工",
      Owner: "老板",
      RequestANewOTP: "获取新的验证码",
      YouHaveSelectdSMSAsNotificationChannel: "您已选择短信作为通知渠道，您将收到您提供手机号码的验证码。请输入验证码以完成注册，",
      DataProtectionGuidelines: "数据保护指南",
      GeneralTermsConditions: "一般条款和条件",
      PleaseEnterYourPhoneNumber: "请输入您的电话号码",
      CellPhoneNumberIsRequired: "手机号码是必填项",
      pleaseAgreeOurTermsAndConditions: "确认同意网站的条款和条件。",
      pleaseAgreeOurDataProtectionPolicy: "确认同意网站的数据保护政策。",
      pleaseAgreeOurLegalNotice: "确认同意网站的法律声明。",
      PleaseChooseYourRegion: "请选择您所在地区",
      PleaseEnterOTP: "请输入验证码",
      LoginToProceed: "登录以继续",
      Login: "登录",
      RegisterNow: "立即注册",
      DontHaveAnAccount: "没有帐户",
      OTP: "验证码",
      RequestANewOTP: "请求新的验证码",
      YourApplicationIsPendingApproval: "您的申请正在等待批准，请稍候！",
      success: "成功",
      rule_list: "历史版本",
      AccountData: "账户数据",
      CountryRegion: "国家/地区",
      Role: "角色",
      ModifyBasicInformation: "公司照片",
      PleaseFillInYourEmail: "您的电子邮件格式不正确，请重新输入！",
      CompanyData: "公司数据",
      eXtraID: "eXtra ID",
      ModifyInformation: "修改信息",
      ModifyKeyInformation: "修改关键信息",
      ChangeCompanyPhoto: "更改公司照片",
      LogOut: "退出",
      NoDataAvailable: "无可用数据",
      Search: "搜索",
      Modify: "修改",
      PleaseCheckTheDataProtectionPolicyAndLegalNotice: "请查看数据保护政策和法律声明！",

      // 人员管理
      RoleManagement: "角色管理",
      Time: "时间",
      Refuse: "拒绝",
      Accept: "接受",
      DoYouConfirmToApproveIt: "您确认批准吗？",
      DoYouConfirmToRejectIt: "您确认要拒绝吗？",
      PleaseConfirmAgain: "通知",
      RoleInformation: "角色信息",
      Name: "姓名",
      Activate: "激活",
      Disable: "禁用",
      IdentitySettings: "身份设置",
      DoYouConfirmToChangeThisInformation: "您确认要更改此信息吗？",
      DoYouConfirmToActivateThisAccount: "您确认激活此帐户吗？",
      DoYouConfirmToDisableThisAccount: "您确认要禁用此帐户吗？",

      // article
      PurchaseRecord: "采购记录",
      TotalPurchase: "采购总额",
      valueSGD: "价值（美元）",
      TotalPurchaseValue: "总购买价值（美元）",
      TotalPurchaseAmt: "总采购金额（美元）",
      ProcurementRecords: "采购记录",
      View: "查看",
      LatestArticles: "最新文章",
      LearnMore: "了解更多>",

      // Autoparts
      AutoParts: "汽车零部件",
      ViewMore: "查看更多>",
      BoschGroup: "博世集团",
      BoschMobilityAftermarket: "博世移动售后市场",

      // BSSList
      WelcomeBoschModulePartner: "欢迎博世模块合作伙伴！",
      MyBenefits: "我的福利 >",
      MoreInfoAboutModule: "更多信息 >",
      AnnualPartsTarget: "年度零件目标",
      StillToGo: "确认前往",
      ContractTarget: "合同目标",
      TotalAchievement: "合计",
      Year: "年份",
      ActualSales: "实际销售额",
      ContractPeriod: "合同期限",
      years: "年",
      JoinDate: "合同开始日期",
      KeepUpTheGreatProgress: "继续！",
      DealerWillGetInTouchWithYouSoon: "经销商会尽快与您联系。",
      ViewMyContract: "查看我的合同",
      Marketing: "营销",
      promotionZone: "晋升",
      Attended: "参加",
      Achieved: '可领取',
      Done: "结束",
      Received: "已收到",
      Abandoned: "放弃",
      to: "至",
      CurrentPurchase: "当前采购",
      SGD: "SGD",
      MarketingPromotionZone: "营销推广区",
      NotAttended: "无法使用的",

      // contact_us
      Like: "喜欢",
      Comment: "评论",
      SaySometihing: "说点什么",
      Sending: "发送",
      Send: '获取验证码',
      User: "用户",
      PleaseTypeWords: "请输入",
      AutomotiveTech: "汽车技术",
      views: "查看",

      // Home
      FunctionalityInDevelopment: "开发中的功能",

      // Module
      Apply: "申请",
      JoinUsLogIn: "加入我们/登录",
      JoinUs: "加入我们",
      BoschModuleConcept: "博世 module 概念",
      IHaveReadAndAcceptedTheBoschModulePolicy: "我已阅读并接受博世 module 政策",
      SignNow: "签名",
      PleaseFillInTheBelowArea: "请填写以下信息",
      PleaseFillInYourFirstNameUnrecognizedOrIncorrectSignatureWillLeadToRegistrationFailure: "请填写您的名字，无法识别或不正确的签名将导致注册失败",
      PlaeaseFillInYourFirstName: "请填写您的名字",
      pleaseCheckTheBoschModulePolicy: "请查看网站政策",
      KindlyProvideSignatureOrFullName: "请提供名称",

      // MyAccount
      loggedInSuccessfully: "登录成功！",
      IAlreadyHaveAnOTP: "我已经有验证码了",
      Tel: "电话",
      PendingIdentityForReview: "待审查账户",
      EnterBoschModuleTiering: "进入博世 Module >",
      CurrentMember: "当前成员",
      EarnPointsEveryDay: "每天赚取积分",
      eXtraMall: "易家商城",
      eXtraPoints: "易家积分",
      eXtraPoint: "易家积分",
      Go: "继续",
      Redeem: "兑换",
      MyServices: "我的服务",
      eXtra: "易家",
      Order: "顺序",
      Points: "积分",
      Details: "详情",
      Personal: "我的",
      Data: "数据",
      Privacy: "隐私",
      Clause: "条款",
      PersonalData: "个人资料",
      eXtraWokrshopCandidate: "会员资格待定",
      KevinCarRepairServiceCenter: "汽车维修服务中心",
      MoreInfoAboutCompany: "更多关于公司的信息",
      MoreInfoAboutYou: "更多关于我的信息",
      MyFirstName: "我的名字",
      MyLastName: "我的姓",
      BossMobileNumber: "老板手机号",

      // Recordltem
      time: "时间",
      item: "项目",
      Qty: "数量",
      PN: "PN码",
      dealer: "经销商",
      Download: "下载",

      // Rewards
      expiresOn: "截止时间",
      ActivityRules: "活动规则",
      CheckInDone: "签到完成",
      CheckInNOW: "立即签到",

      // Video
      FoundTheQRCode: "查看二维码",
      PleaseUseYourMobilePhoneToLog: "请使用您的手机登录，以便成功扫码！",
      PleaseAllowAuthorizedUseOfYourPhone: "请允许授权使用您的手机摄像头并再次扫描！",

      // WhatsOn
      ThoEvontTeasorGroupOllors: "有什么新闻",
      ThoEvontTeasorGroupOllors: "事件展示组允许在一个组件内突出显示多个事件",

      ThePointsDataWillUpdateMonthlyPleaseStayInformedWithUs: "积分数据将每月更新，敬请期待！",







      WorkRole: "工作角色",
      ICanNotFindMyDistributor: "找不到我的经销商",
      AllAutoparts: "全部",
      BatterySpecialist: "电池相关",
      Others: "其他",
      Cancel: "取消",
      PleaseEnterTheCorrectCompanyNameAndMobileNumber: "请输入正确的公司名称和手机号码",
      ALL: "全部",
      AccessOurFullRangeOfAvailableProductsOnTheGoSimplyClickOnYourProductGroupOfInterestToDiveIn: "随时随地访问我们的全系列可用产品-只需点击您感兴趣的产品组即可深入了解！",
      searchPartnumberItem: "搜索产品料号、产品名称",
      searchPartnumberDealer: "搜索产品料号、经销商",
      ModuleJoinData: "Module 数据",
      ViewMyContract: "查看我的合同",
      NoContractHint: "我们还没有查询到您的合约，请联系您的博世销售。",
      NoticeOfExpirationOfContract: "您的合约已到期！请等待博世安排续约。",

      Reset: "重置",
      PleaseAccessThroughMobileBrowser: '请手机浏览器访问',

      Enter: "确认",
      OverallAnnualBoshPurchase:'年度Bosch采购总额',
    }
  }
};
const localLanguage = localStorage.getItem('localLanguage') || 'en'
const i18n = new VueI18n({
  locale: localLanguage, // 默认语言
  messages,
});

export default i18n;