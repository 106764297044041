<style lang="scss" scoped>
.banner_text {
    position: absolute;
    top: 35%;
    left: 3%;
    font-size: 20px;
    color: #676767;
}

.body_center {
    padding-bottom: 25px;
    font-size: 20px;
    color: #989898;
}

.top {
    position: relative;
    margin-bottom: 77px;
}

.icon3 {
    width: 48px;
    height: 48px;
    margin-bottom: 13px;
}

.ul {
    width: 663px;
    position: absolute;
    top: 50%;
    left: 3%;
    font-size: 20px;
    color: #676767;
    justify-content: space-between;
}

.border {
    width: calc(100% - 48px);
    background: #B8D6FF;
    height: 1px;
    position: absolute;
    top: 24px;
    left: 24px;
}

.li {
    z-index: 1;
    width: 87px;
    text-align: center;
}

.li>div {
    font-size: 18px;
    line-height: 18px;
    height: 36px;
}

.btn {
    position: absolute;
    top: 80%;
    background: #117281;
    color: #fff;
    width: 544px;
    height: 55px;
    margin-left: 39px;
}

.title {
    margin-top: 105px;
    font-size: 28px;
    margin-bottom: 28px;
}

.bom {
    box-sizing: border-box;
    margin-top: 76px;
    background: #FFFFFF;
    box-shadow: 0px 1px 16px rgba(0, 123, 192, 0.09);
    border-radius: 4px;
    padding: 20px 34px 52px 34px;
    margin-bottom: 59px;
}

.bom_title {
    font-size: 28px;
    color: #000;
    font-weight: bold;
}

.bom_li {
    background: #ECF8FF;
    width: calc(50% - 6px);
    padding: 21px;
    border-radius: 4px;
}

.bom_ul {
    margin-top: 28px;

}

.icon1 {
    width: 33px;
    height: 33px;
    margin-right: 16px;
}

.name {
    font-size: 22px;
    color: #000000;
    margin-bottom: 0;
}

.value {
    font-size: 18px;
    color: #EB5336;
    margin-bottom: 11px;
}

.text {
    margin-bottom: 0;
    font-size: 18px;
    color: #676767;
}

.bom_btn {
    width: 177px;
    height: 42px;
    background: #007BC0;
    color: #fff;

}

/* 移动端 */
@media (max-width: 1200px) and (min-width: 300px) {

    .banner_text {
        font-size: 12px;
        line-height: 14px;
        top: 35%;
        width: 250px;
        left: 15px;

    }

    .body_center {
        font-size: 16px;
        padding: 0 20px 25px 20px;

    }

    .border {
        width: calc(100% - 60px);
        background: #B8D6FF;
        height: 1px;
        position: absolute;
        top: 16px;
        left: 30px;
    }

    .ul {
        top: 52%;
        left: 17px;
        width: calc(100% - 34px);
        display: flex;
        justify-content: space-between;
        align-items: center;

    }

    .li>div {
        font-size: 10px;
        line-height: 11px;
        height: 22px;
    }


    .icon3 {
        width: 32px;
        height: 32px;
        margin-bottom: 6px;
    }

    .btn {
        top: 76%;
        box-sizing: border-box;
        width: calc(100% - 34px);
        margin-left: 17px;
        height: 44px;
        border-radius: 0;

    }

    .bom_ul {
        flex-direction: column;
    }

    .bom {
        margin-top: 0;
        padding: 15px 15px 13px 15px;
    }

    .bom_title {
        font-size: 16px;
    }

    .bom_li {
        background: #ECF8FF;
        width: calc(100% - 6px);
        padding: 16px 11px 16px 21px;
        margin-bottom: 10px;

    }

    .icon1 {
        margin-top: 5px;
        width: 22px;
        height: 22px;
        margin-right: 13px;
    }

    .name {
        font-size: 12px;
    }

    .value {
        font-size: 12px;
        margin-bottom: 4px;
    }

    .text {
        font-size: 10px;
    }

    .bom_btn {
        font-size: 10px;
        width: 95px;
        height: 27px;
        background: #007BC0;
        color: #fff;

    }

}
</style>
<template>
    <div class="page_d">

        <!-- 签到规则 -->
        <div class="pcShow" v-if="Layer == 1">
            <div class="layer_bg ">
                <div class="layer ">
                    <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/icon11.png" alt="img"
                        class="layer_img">

                </div>
                <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/close.png" alt="img"
                    class="layer_close pointer" @click="Layer = 0">

            </div>
        </div>
        <div class="ydShow" v-if="Layer == 1">
            <div class="layer_bg">
                <div class="layer">
                    <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/icon12.png" alt="img"
                        class="layer_img">

                </div>
                <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/close.png" alt="img"
                    class="layer_close pointer" @click="Layer = 0">

            </div>
        </div>



        <div class="container ydShow_padding0" v-if="clientInfo && !msg">
            <div class="banner_d row12 pcShow">
                <img :src="localLanguage == 'my'?'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/my/icon7.png':'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/icon7.png'" alt="img"
                    class="banner">
                <div class="top_div ">
                    <div>
                        <div>{{ clientInfo.bssPointValue || 0 }} {{ clientInfo.bssPointValue>0?$t("message.eXtraPoints"):$t("message.eXtraPoint") }}</div>
                        <div class="top_div_text">{{$t("message.ThePointsDataWillUpdateMonthlyPleaseStayInformedWithUs")}}</div>
                    </div>
                   
                    <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/icon8.png" alt="img"
                        class="icon8 pointer" @click="goWeb('https://extraloyaltyaps.page.link/app')">
                </div>
            </div>
            <div class="banner_d row12 ydShow">
                <img :src="localLanguage == 'my'?'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/my/icon9.png':'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/icon9.png'" alt="img"
                    class="banner">
                <div class="top_div">
                    <div>
                        <div>
                            <div>{{ clientInfo.bssPointValue || 0 }} {{ clientInfo.bssPointValue>0?$t("message.eXtraPoints"):$t("message.eXtraPoint") }}</div>
                            

                        </div>
                        <div class="top_div_text">{{$t("message.ThePointsDataWillUpdateMonthlyPleaseStayInformedWithUs")}}</div>
                    </div>

                    <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/icon8.png" alt="img"
                        class="icon8 pointer" @click="goWeb('https://extraloyaltyaps.page.link/app')">

                </div>
            </div>
            <div class="container ydShow">
                <div class="nav_d">
                    <img :src="localLanguage == 'my'?'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/my/1.png':'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/1.png'" 
                        alt="img" class="nav_li pointer" @click="goWeb('https://extraloyaltyaps.page.link/app')">
                    <img :src="localLanguage == 'my'?'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/my/2.png':'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/2.png'"  alt="img"
                        class="nav_li pointer" @click="goWeb('https://extraloyaltyaps.page.link/app')">
                    <img :src="localLanguage == 'my'?'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/my/3.png':'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/3.png'" alt="img"
                        class="nav_li pointer" @click="msg = true">
                </div>
            </div>
            <div class="container pcShow">
                <div class="nav_d">
                    <img :src="localLanguage == 'my'?'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/my/-1.png':'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/-1.png'"
                        alt="img" class="nav_li pointer" @click="goWeb('https://extraloyaltyaps.page.link/app')">
                    <img :src="localLanguage == 'my'?'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/my/-2.png':'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/-2.png'" alt="img"
                        class="nav_li pointer" @click="goWeb('https://extraloyaltyaps.page.link/app')">
                    <img :src="localLanguage == 'my'?'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/my/-3.png':'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/-3.png'" alt="img"
                        class="nav_li pointer" @click="msg = true">
                </div>
            </div>

        </div>
        <!-- 规则 -->

        <div class="container ydShow_padding0" v-if="msg">
            <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/icon10.png" alt="img" class="banner">




        </div>
        <div class="food_btns" v-if="msg">
            <div class="food_btn_center pointer" @click="msg = false">{{ $t("message.Back") }}</div>
        </div>
        <!-- 游客 -->
        <div class="container ydShow_padding0" v-else-if="!clientInfo">
            <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/signIn/icon10.png" alt="img" class="banner">




        </div>
        <div class="food_btns" v-if="!clientInfo">
            <div class="food_btn_center pointer" @click="clare()">{{ $t("message.JoinUs") }}</div>
        </div>
        <div class="layer_bg" v-if="Layer == 2">
            <div class="layer">
                <img class="layer_img" src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/1_layer.png"
                 alt="" srcset="">

            </div>
            <img class="layer_close pointer" src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/close.png"
                @click="Layer = 0">
        </div>
    </div>
</template>

<script>

import { EventBus } from "../main"
export default {

    components: {
    },
    name: "Rewards",
    props: {
        msg: String,
    },
    data() {
        return {
            msg: false,
            Layer: 0,
            signList: '',
            isSigned: 0,
            clientInfo: '',
            localLanguage:'',
        }
    },
    created() {
        var that = this
        if (localStorage.getItem("client_info")) {
            this.clientInfo = JSON.parse(localStorage.getItem("client_info"))
            console.log('clientInfo==', this.clientInfo)
            if( JSON.parse(localStorage.getItem("client_info")).verifyStatus == '02'){
                this.getclient_isSign()
            }
           
        }
        if (localStorage.getItem("country")) {
            this.country = JSON.parse(localStorage.getItem("country"))
            this.localLanguage = localStorage.getItem('localLanguage')
        }
    },
    mounted() {
        var that = this
        EventBus.$on('localLanguage', (newValue) => {
            console.log('Home_localLanguage===',localStorage.getItem('localLanguage'))
            this.localLanguage =localStorage.getItem('localLanguage')
            
        })
    },
    methods: {
        goWeb(url){
            var Mobile = this.checkMobile()
            console.log('Mobile==',Mobile)
            if(Mobile){
                window.open(url, "_blank");
            }else{
                this.Layer = 2
            }
           
           
        },
        govideo() {
            var that = this
            // that.$router.push({
            //     name: "videoSao",
            // });
        },
        clare() {
            var that = this
            that.$router.push({
                name: "login_index",
            });

        },
        getclient_info() {
            var that = this
            that.$flyNo.request({
                method: 'GET',
                url: that.httpUrl.client_info,
                body: {
                }
            }).then(res => {
                if (!res) return false
                console.log('--------------——', that.httpUrl.client_info, '_res==', res)
                that.clientInfo = res.resultData.clientInfo
                localStorage.setItem("client_info", JSON.stringify(res.resultData.clientInfo))
                console.log('clientInfo______________', that.clientInfo)

            })
        },
        getactivity_scan() {
            var that = this
            that.$flyNo.request({
                method: 'POST',
                url: that.httpUrl.activity_scan,
                body: {
                    scanCode: localStorage.getItem("qrcode")
                }
            }).then(res => {
                localStorage.setItem("qrcode",'')
                if (!res) return false
                console.log('--------------——', that.httpUrl.activity_scan, '_res==', res)
                var scan = res.resultData.scanInfo
                var msg = 'You got +' + scan.bonusPointValue +'eXtra points'
                that.$alert(msg, 'Checkin Success！', {
                    confirmButtonText: 'ok',
                    callback: action => {
                    }
                });
                
            })
        },
        getclient_isSign() {
            var that = this
            // 获取今天的日期
            const today = new Date();
            const yearToday = today.getFullYear();
            const monthToday = today.getMonth() + 1;
            const dayToday = today.getDate();

            // 获取过去七天的日期
            const pastSevenDays = [];
            for (let i = 0; i < 7; i++) {
                const date = new Date();
                date.setDate(today.getDate() - i - 1);
                const yearPast = date.getFullYear();
                const monthPast = date.getMonth() + 1;
                const dayPast = date.getDate();
                pastSevenDays.push({
                    year: yearPast,
                    month: monthPast,
                    day: dayPast
                });
            }
            let seven = pastSevenDays[pastSevenDays.length - 1]
            that.$flyNo.request({
                method: 'GET',
                url: that.httpUrl.client_isSign,
                body: {
                    page: 1,
                    pageSize: 7,
                    endDate: `${yearToday}-${monthToday}-${dayToday}`,
                    startDate: `${seven.year}-${seven.month}-${seven.day}`
                }
            }).then(res => {
                if (!res) return false
                console.log('--------------——', that.httpUrl.client_isSign, '_res==', res.resultData)
                that.signList = res.resultData.signList
                let signList = res.resultData.signList.signlist
                if (signList.length > 3) {
                    let three = signList[3]
                    three.isSigned == 1 ? that.isSigned = 1 : ''
                }
            })

        },
        immediatelySign() {
            var that = this
            that.$flyNo.request({
                method: 'POST',
                url: that.httpUrl.client_sign,
                body: {
                    signFrom: "app"
                }
            }).then(res => {
                if (!res) return false
                console.log('--------------——', that.httpUrl.client_sign, '_res==', res.resultData)
                that.getclient_isSign()
            })
        }
    }
}
</script>


<style lang="scss" scoped>
.icon13 {
    width: 18px;
    height: auto;
}

.nav_d {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;

    .nav_li {
        box-sizing: border-box;
        width: 32%;
    }
}

.banner_d {
    margin-bottom: 11px;
    font-size: 24px;
    color: #000000;
}

.banner {
    width: 100%;
    height: auto;
    margin: 0;
}

.top_div {
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // height: 94px;
    padding: 40px 41px;
    position: relative;
    line-height: normal;
    top: -10px;
    z-index: 10;
    box-shadow: 0px 4px 4px 0px #0000000D;
    color: #000;
    font-weight: bold;

}

.top_div_text {
    font-size: 18px;
    flex: 1;
    text-align: right;
    margin-right: 21px;
    margin-top: 7px;
    color: #676767;
    font-weight: normal;
}

.icon8 {
    width: 33px;
    height: 33px;

}

/* 移动端 */
@media (max-width: 1200px) and (min-width: 300px) {
    .icon13 {
        width: 13px;
        height: auto;
    }

    .nav_d {
        margin-bottom: 15px;
    }

    .top_div {
        margin: 0 auto;
        width: calc(100% - 22px);
        padding: 25px 17px;
        font-size: 18px;
        display: flex;
        align-items: center;
        border-radius: 8px;
    }

    .top_div_text {
        margin-top: 10px;
        font-size: 14px;
        text-align: left;
    }

    .icon8 {
        width: 21px;
        height: 21px;

    }

}
</style>